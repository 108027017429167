import './App.css';


function App() {
  return (
    <div className="App">
      <div className='bg'>
        <div className='icon'>
        </div>

        <div className='title'>
          Episode:Love Unlimited
        </div>

        <div className='download'>
          <div className='ios'
            onClick={() => {
              window.open("https://apps.apple.com/app/id6476787302");
            }}>
          </div>
          <div className='android'
            onClick={()=>{
              window.open("https://play.google.com/store/apps/details?id=com.episode.app");
            }}
          >
          </div>
        </div>
        <div className='phone'>
        </div>

        <div className='footer'>

          <a
            style={{
              marginRight: '20px'
            }}
            href='https://episode.vip/terms_of_use.html'
            target='_blank'
            rel="noreferrer"
          >Terms of Use</a>
          <a 
            href='https://episode.vip/privacy_policy.html'
            target='_blank'
            rel="noreferrer"
          >Privacy Policy</a>

        </div>
      </div>
    </div>
  );
}

export default App;
